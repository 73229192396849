
import { Vue, Component, Prop } from 'vue-property-decorator'
import BaseIcon from './BaseIcon.vue'

@Component({ name: 'Accordion', components: { BaseIcon } })
export default class BaseAccordion extends Vue {
  isAccorActive = false

  @Prop({ default: '' })
  classTitle!: string

  @Prop({ default: '' })
  classContent!: string

  @Prop({ default: '' })
  classIcon!: string

  openAccor(): void {
    this.$emit('change-accordion')
    this.isAccorActive = !this.isAccorActive
  }
}
